import NutrienLogo from '../assets/images/nutrien_logo.png';
import UofCLogo from '../assets/images/uofc.png';
import CoolITLogo from '../assets/images/coolit_logo.png';
import tcenergy from '../assets/images/tcenergy_logo.png';
import uoftLogo from '../assets/images/uoft_logo.png';

let baseLeft = 30;

const ExperienceData = [
    {
        id: 1,
        experience_name: "coolit-integration-engineer",
        title: "Integration Engineer",
        company: "CoolIT Systems",
        logo: CoolITLogo,
        location: "Calgary, Alberta",
        includeStartDate: false,
        endDate: "Present",
        baseLeft: baseLeft - 1.5,
        horizontalWidth: 16.55,
        verticalHeight: 1,
        shortDescription: "At CoolIT, I focus on integrating ERP, PLM, and MES systems to create seamless data communication pathways while ensuring security and reliability remain top priorities.\n\nOne of my most impactful projects has been developing the ECN Checker, a tool designed to streamline and enhance the Engineering Change Notice (ECN) process. This tool has become critical to maintaining high engineering standards at CoolIT, reducing the risk of errors, and saving significant time for the engineering team.",
        fullDescription1: "The ECN Checker plays a vital role in ensuring the quality and consistency of engineering data. By automating the review process, it eliminates manual inefficiencies and enforces strict adherence to CoolIT’s engineering standards. It checks crucial elements—such as Bills of Materials (BOMs), revisions, drawings, and attributes—to ensure they meet all required specifications. Engineers have quickly embraced it as an indispensable tool, significantly improving the accuracy and speed of ECN approvals."
    },
    {
        id: 2,
        experience_name: "uofc-computer-science",
        title: "BSc in Computer Science",
        company: "University of Calgary",
        logo: UofCLogo,
        location: "Calgary, Alberta",
        startDate: "January 2020",
        endDate: "June 2024",
        baseLeft: baseLeft += 15,
        horizontalWidth: 93,
        verticalHeight: 1.8,
        shortDescription: "I completed my Bachelor’s degree in Computer Science at the University of Calgary as an international student. My studies focused on technical skills like software design, data analytics, and system security, gained through hands-on projects and advanced coursework. Beyond the core computer science classes, I explored diverse topics like economics and physics, which broadened my perspective. This mix of practical tech knowledge and varied learning has set me up well for a career in the tech industry.",
        fullDescription1: "My degree gave me a strong technical foundation, especially through advanced courses in my final year. I learned how to design user-friendly software in Human-Computer Interaction and structure complex applications in Software Architecture. Courses like Web-Based Systems and Scalable Data Analytics taught me to build and analyze large-scale systems, while Information Security and Privacy introduced me to protecting data in today’s digital world. Software Requirements Engineering also sharpened my skills in planning and creating tech solutions that meet real user needs. These hands-on, industry-relevant skills have prepared me to tackle modern tech challenges.",
        fullDescription2: "I dug into the core of computer science with courses like Programming Paradigms, Data Structures, and Algorithms & Analysis. These helped me master programming languages, manage data effectively, and understand the logic behind efficient coding. Principles of Operating Systems gave me insight into how computers run under the hood. I also took some interdisciplinary classes like Innovation-Driven Entrepreneurship and Introduction to Business Venturing, which sparked my interest in startups and using tech creatively to solve real-world problems. This mix strengthened both my technical and problem-solving abilities.",
        fullDescription3: "In my first couple of years at the University of Calgary, I built the groundwork for my computer science skills. Courses like Introduction to Computer Science for Majors I & II and Logic I helped me develop a logical mindset for coding and problem-solving. Linear Methods I and Introductory Calculus boosted my analytical thinking, which came in handy as I progressed to more advanced topics. I also explored extra subjects like Principles of Macroeconomics, Microeconomics, Physics and Energy, and Linguistics. These weren’t directly tech-related, but they added variety to my education and helped me see problems from different angles."
    },
    {
        id: 3,
        experience_name: "nutrien-cloud-engineer",
        title: "Cloud Engineer Coop",
        company: "Nutrien",
        logo: NutrienLogo,
        location: "Calgary, Alberta",
        startDate: "May 2023",
        endDate: "May 2024",
        baseLeft: baseLeft += 18,
        horizontalWidth: 15,
        verticalHeight: 1,
        shortDescription: "At Nutrien, my role extended beyond typical cloud engineering tasks to focus on the reliability, security, storage, and networking of critical applications across the company.\n\nOne of my main responsibilities was helping teams troubleshoot unique cloud challenges. The constantly changing nature of cloud engineering meant tackling new problems every day, which helped me build a deeper understanding of cloud technologies and how to use them effectively.",
        fullDescription1: "",
        fullDescription2: "I primarily worked with AWS and Azure, handling tasks like improving security groups for applications and strengthening application security overall. I also developed Python automation scripts using AWS and Azure SDKs to identify underutilized infrastructure, a project that improved cost efficiency and saved the company thousands of dollars."
    },
    {
        id: 4,
        experience_name: "tcenergy-network-engineer-2",
        title: "Network Services Engineering Intern",
        company: "TC Energy",
        logo: tcenergy,
        location: "Calgary, Alberta",
        startDate: "May 2022",
        endDate: "August 2022",
        baseLeft: baseLeft += 25,
        horizontalWidth: 18,
        verticalHeight: 1,
        shortDescription: "In my second term at TC Energy, I took on more technical responsibilities, focusing on data engineering and cloud-based development.\n\nOne of my key projects was writing Python scripts to move data from multiple sources into an AWS Data Lake. These scripts handled data cleaning, structuring, and secure delivery, ensuring consistency and reliability. This gave me hands-on experience with data management in cloud environments.",
        fullDescription2: "I also worked on the Network Services Toolbox, an internal tool, where I added new features, updated Node versions, and maintained the CI/CD pipeline in AWS. Collaborating with TC Energy’s team was a great learning experience that helped me grow my technical skills."
    },
    {
        id: 5,
        experience_name: "tcenergy-network-engineer-1",
        title: "Network Services Engineering Intern",
        company: "TC Energy",
        logo: tcenergy,
        location: "Calgary, Alberta",
        startDate: "May 2021",
        endDate: "August 2021",
        baseLeft: baseLeft += 25,
        horizontalWidth: 17,
        verticalHeight: 1,
        shortDescription: "At TC Energy, my first term was my introduction to the IT field, where I quickly adapted to the challenges of working in a technical environment.\n\nI focused on building Power BI dashboards while working closely with a data scientist. Their guidance helped me get up to speed with Power BI, and I soon started delivering dashboards that provided valuable insights for the business.",
        fullDescription2: "I also wrote Python scripts to pull data from different sources like databases and cloud systems, which involved a lot of SQL work. This hands-on experience improved my data handling skills and gave me a solid understanding of cloud technologies, motivating me to learn more about tools like AWS."
    },
    {
        id: 6,
        experience_name: "uot-computer-engineering",
        title: "Computer Engineering",
        company: "University of Tripoli",
        logo: uoftLogo,
        location: "Tripoli, Libya",
        includeStartDate: false,
        endDate: "January 2019",
        baseLeft: baseLeft += 33,
        horizontalWidth: 15.09,
        verticalHeight: 1,
        shortDescription: "I studied at the University of Tripoli for two semesters, focusing on first-year engineering courses like Calculus 1 and 2, Physics 1 and 2, Linear Algebra, and Engineering Drawing. I specialized in computer engineering, but two months into the program, I traveled to Canada to study Computer Science at the University of Calgary.",
    },
    {
        id: 7,
        experience_name: "high-school",
        title: "High School",
        company: "مدرسة طلائع التقدم",
        location: "Tripoli, Libya",
        includeStartDate: false,
        endDate: "May 2017",
        baseLeft: baseLeft += 15,
        horizontalWidth: 13.09,
        verticalHeight: 1,
        shortDescription: "Graduated from high school with a focus on science and mathematics.",
        fullDescription1: "",
        fullDescription2: ""
    },
    {
        id: 8,
        experience_name: "middle-school",
        title: "Middle School",
        company: "Isleworth & Syon School",
        location: "London, UK",
        includeStartDate: false,
        endDate: "May 2012",
        baseLeft: baseLeft += 13,
        horizontalWidth: 13.09,
        verticalHeight: 1,
        shortDescription: "Attended middle school in London, focusing on foundational studies in science and technology.",
        fullDescription1: "",
        fullDescription2: ""
    },
    {
        id: 9,
        experience_name: "junior-school",
        title: "Junior School",
        company: "مدرسة التقدم",
        location: "Tripoli, Libya",
        includeStartDate: false,
        endDate: "May 2010",
        baseLeft: baseLeft += 13,
        horizontalWidth: 13.09,
        verticalHeight: 1,
        shortDescription: "",
        fullDescription1: "",
        fullDescription2: ""
    },
    {
        id: 10,
        experience_name: "elementary-school",
        title: "Primary School",
        company: "St Paul's School",
        location: "Newcastle, UK",
        includeStartDate: false,
        endDate: "May 2007",
        baseLeft: baseLeft += 13,
        horizontalWidth: 0,
        shortDescription: "",
        fullDescription1: "",
        fullDescription2: ""
    }
];

export default ExperienceData;

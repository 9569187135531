import React from 'react';
import animeLogo from '../assets/images/mypic.jpg';
import StackToolsSection from '../components/StackToolsSection';

export default function Home() {

    return (
        <div className="flex justify-between flex-nowrap h-full min-w-max">

            <div className="flex h-full min-w-max relative left-[3.4rem] lg:left-[-4rem] space-x-12 lg:space-x-28 overflow-hidden">

                <div className="flex flex-col min-w-[23rem] lg:ml-[8rem] mt-[7%] h-sm:mt-16 lg:mt-[1%] xl:mt-[2%]">

                    <div className="flex lg:flex-row justify-center space-x-4 lg:space-x-28 h-sm:space-x-[0.5rem]">

                        <div className="flex flex-col justify-center h-full font-normal lg:font-light lg:space-y-2">
                            {/* Name */}
                            <h1 className="text-xl lg:text-4xl tracking-wider h-sm:text-[0.9rem]">Mohammed Allam</h1>

                            {/* Role */}
                            <h2 className="text-sm lg:text-xl text-secondDirtyWhite h-sm:text-[0.6rem]">Software Developer</h2>
                        </div>

                        {/* Image */}
                        <div className=" w-[10rem] h-[9rem] lg:w-[40rem] lg:h-[23rem] lg:ml-[4rem] mr-7 h-sm:w-[8rem] h-sm:h-[8rem] h-sm:mt-2 ">
                            {/* Placeholder for image */}
                            <img
                                src={animeLogo}
                                alt="Profile"
                                className=" object-cover w-full h-full rounded-full lg:rounded-full"
                            />
                        </div>
                    </div>
                </div>

                <div className="flex flex-col justify-start align-top items-start mt-[3%] lg:mt-[1%] xl:mt-[3%] pr-[10rem]">
                    <StackToolsSection />
                </div>
            </div>
        </div >
    )
}


